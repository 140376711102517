import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Knobs,
	DocPageMeta,
	InlineMessage,
	ImportExample,
	Example,
	Code,
	Header,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { Card } from "../card/card";
import { Paragraph } from "../../text/paragraph/paragraph";
import { InlineText } from "../../text/inline-text/inline-text";
import { TextContainer } from "../../text/text-container/text-container";
import { Ul } from "../../text/list/list";
import { Content } from "../content/content";
import { H3 } from "../../text/heading/heading";
import { Divider } from "./divider";

export const Meta: DocPageMeta = {
	title: "Divider",
	category: "Structure",
	notepad: "https://hackmd.io/qLndK2ThRa-kJkZTWXeHTw",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Divider"
			subTitle="Use this component to create a clear visual divide between content sections."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={Divider} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					Sometimes, whitespace and headings aren't enough to visually separate content sections. In
					such cases, the divider component can help. Essentially, it's a thin full-width horizontal
					rule. As a result, it can only act as a border between vertically stacked sections.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					Here you can see a standalone default implementation of the divider component.
				</Paragraph>
				<Example fn={defaultExample} />
				<Header.H3>Container</Header.H3>
				<Paragraph>
					The divider component should only be used inside visual containers, such as{" "}
					<GatsbyLink to="/lib/components/Structure/Card">cards</GatsbyLink>,{" "}
					<GatsbyLink to="#">(dead link) modals</GatsbyLink>, or{" "}
					<GatsbyLink to="#">(dead link) sidepanels</GatsbyLink>. You can see an example of that
					here. The card features two content sections, with topics so unrelated, that a divider is
					needed to emphasize this distinction. Also, note that the{" "}
					<GatsbyLink to="/lib/components/Structure/Card">card header</GatsbyLink> features a
					divider to signal that it's a distinct section of the card.
				</Paragraph>
				<Example fn={contextExample} />
				<Header.H2>Properties</Header.H2>
				<Paragraph>
					The divider component doesn't have any properties. So, this section isn't quite as
					interesting as usual.
				</Paragraph>
				<Knobs component={Divider} initialProps={{}} />
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							"Use the divider component to create a clear visual divide between content sections.",
							<>
								Use the divider component inside visual containers, such as{" "}
								<GatsbyLink to="/lib/components/Structure/Card">cards</GatsbyLink>,{" "}
								<GatsbyLink to="#">(dead link) modals</GatsbyLink>, or{" "}
								<GatsbyLink to="#">(dead link) sidepanels</GatsbyLink>.
							</>,
							"Use the divider component when users need to quickly scan and find specific content.",
							<>
								Use the divider component in combination with{" "}
								<GatsbyLink to="/lib/components/Text/Heading">headings</GatsbyLink>, not instead of
								them.
							</>,
							<>
								Dividers should be the same width as their container. So, place them outside of{" "}
								<GatsbyLink to="/lib/components/Structure/Content.Layout">layout</GatsbyLink> or{" "}
								<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> components.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Paragraph>
						Whitespace and <GatsbyLink to="/lib/components/Text/Heading">headings</GatsbyLink>{" "}
						create sufficient visual separation between content sections.
					</Paragraph>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						The divider component doesn't use the <Code>{"<hr>"}</Code> tag. That's because this
						HTML tag carries semantic meaning, which assistive technology communicates to its users.
						Specifically, the <Code>{"<hr>"}</Code> tag signals a thematic break. As a result, it
						should not be used for presentational purposes.
					</Paragraph>
					<Paragraph>
						However, our divider component is intended to be used for presentational purposes.
						Presentational components don't do much for assistive technology users. They are better
						served by headings that explicitly signal and label thematic breaks.
					</Paragraph>
					<Paragraph>
						<InlineText size="medium" emphasis="medium">
							To ensure accessibility:{" "}
						</InlineText>{" "}
						Consider using a <GatsbyLink to="/lib/components/Text/Heading">heading</GatsbyLink>{" "}
						instead of a divider, or use a heading{" "}
						<InlineText size="medium" emphasis="medium">
							and
						</InlineText>{" "}
						a divider.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph>There are no writing guidelines for this component.</Paragraph>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const defaultExample = () => <Divider />;

const contextExample = () => (
	<Card>
		<Card.Header>Card</Card.Header>
		<Content>
			<TextContainer>
				<H3>First section</H3>
				<Paragraph>
					In molestie sem at ligula semper, at imperdiet ligula pulvinar. Fusce egestas porttitor
					dui. Vestibulum hendrerit sagittis neque, ut tempor nisl vestibulum eget.
				</Paragraph>
				<Paragraph>
					Morbi nec mollis dolor. Donec porta arcu non dui efficitur, a condimentum quam cursus.
					Fusce non pharetra nisi. Nunc pharetra fermentum massa. Fusce nec nibh at orci mattis
					vehicula feugiat at elit.
				</Paragraph>
			</TextContainer>
		</Content>
		<Divider />
		<Content>
			<TextContainer>
				<H3>Second section</H3>
				<Paragraph>
					Praesent cursus tortor nec faucibus ullamcorper. Ut enim leo, faucibus ut magna at,
					pharetra lobortis nisi. Maecenas eleifend, elit quis finibus hendrerit, neque quam
					tincidunt risus, eu facilisis nulla tellus vitae lectus.
				</Paragraph>
				<Paragraph>
					Ut vitae fermentum mauris. Nunc at justo quis urna efficitur consectetur ac sit amet
					augue. Vivamus sagittis nunc non ultrices volutpat. Maecenas tincidunt lobortis massa, sed
					pulvinar diam. Proin ut scelerisque felis. Suspendisse non turpis at arcu rutrum semper at
					non diam.
				</Paragraph>
			</TextContainer>
		</Content>
	</Card>
);
